import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable, of } from "rxjs";
import { environment } from "../../environments/environment";
import { Settings } from "../models/Settings";

@Injectable({
  providedIn: "root",
})
export class ApiService {
  private serverUrl: string = environment.api;
  private host: string = environment.host;
  private retraderBackendUrl: string = environment.retraderBackend;

  constructor(private http: HttpClient) {}

  buildUrl(url: string): string {
    return `${this.serverUrl}${url}`;
  }

  uploadMedia(title: string, media: Array<File>, closed: boolean) {
    // We are using multer to upload files to S3. Multer requires formdata format in order to read the passed media
    const formData = new FormData();
    formData.append("title", title);
    media.forEach((f) => formData.append("media", f));
    return this.http.post(
      this.buildUrl(
        `/api/admin/v1/${closed ? "uploadClosedMedia" : "uploadOpenMedia"}`
      ),
      formData
    );
  }

  uploadCategoryMedia(title: string, media: File) {
    // We are using multer to upload files to S3. Multer requires formdata format in order to read the passed media
    const formData = new FormData();
    formData.append("title", title);
    formData.append("media", media);
    return this.http.post(
      this.buildUrl(`/api/admin/v1/uploadCategoryMedia/`),
      formData
    );
  }

  getBuildVersion() {
    return this.http.get(this.buildUrl(`/api/admin/v1/buildVersion`));
  }

  getCloudfrontOpenURL() {
    return this.http.get(this.buildUrl(`/api/admin/v1/cloudfrontOpen`));
  }

  getS3OpenURL() {
    return this.http.get(this.buildUrl(`/api/admin/v1/s3Open`));
  }

  getImageFromCloudFront(url) {
    return this.http.get(url, {
      headers: new HttpHeaders({
        'Accept': 'image/*',
        'Content-Type': 'image/*'
      }),
      responseType: 'blob'
    });
  }

  getAllGames(url) {
    return this.http.get(this.buildUrl(url));
  }

  getAllUsers(url) {
    return this.http.get(this.buildUrl(url));
  }

  getAllCategories(url) {
    return this.http.get(this.buildUrl(url));
  }

  getAllGroups(url) {
    return this.http.get(this.buildUrl(url));
  }

  getAllBundles(url) {
    return this.http.get(this.buildUrl(url));
  }

  createEventFromBundle(data) {
    return this.http.post(this.buildUrl("/api/admin/v1/createEventFromBundle"), data);
  }

  getAllSchools(url) {
    return this.http.get(this.buildUrl(url));
  }

  getSingleGame(id) {
    return this.http.get(this.buildUrl(`/api/admin/v1/all/games/${id}`));
  }

  getTemplateGameEvents(id) {
    return this.http.get(this.buildUrl(`/api/admin/v1/templateGameEvents/${id}`));
  }

  getGameEvents(id) {
    return this.http.get(this.buildUrl(`/api/admin/v1/gameEvents/${id}`));
  }

  copyGame(data) {
    return this.http.post(this.buildUrl("/api/admin/v1/copyGame"), data);
  }

  newGroup(newGroup) {
    return this.http.post(this.buildUrl(`/api/admin/v1/newGroup`), newGroup);
  }

  editCategory(editCategory) {
    return this.http.post(
      this.buildUrl(`/api/admin/v1/editCategory`),
      editCategory
    );
  }

  getSingleCategory(id) {
    return this.http.get(this.buildUrl(`/api/admin/v1/all/categories/${id}`));
  }

  getCategorySettings(id): Observable<Settings[]> {
    return this.http.get<Settings[]>(this.buildUrl(`/api/admin/v1/categories/${id}/settings`));
  }

  newCategory(newCategory) {
    return this.http.post(
      this.buildUrl(`/api/admin/v1/newCategory`),
      newCategory
    );
  }

  editGame(editGame) {
    return this.http.post(this.buildUrl(`/api/admin/v1/editGame`), editGame);
  }

  editEvent(editGame) {
    return this.http.post(this.buildUrl(`/api/admin/v1/editEvent`), editGame);
  }

  editGroup(editGroup) {
    return this.http.post(this.buildUrl(`/api/admin/v1/editGroup`), editGroup);
  }

  deleteGroup(deleteGroup) {
    return this.http.post(
      this.buildUrl(`/api/admin/v1/deleteGroup`),
      deleteGroup
    );
  }

  deleteGroupUsers(deleteGroupUsers) {
    return this.http.post(
      this.buildUrl(`/api/admin/v1/deleteGroupUsers`),
      deleteGroupUsers
    );
  }

  newBundle(newBundle) {
    return this.http.post(this.buildUrl(`/api/admin/v1/newBundle`), newBundle);
  }

  editBundle(editBundle) {
    this.deleteBundle(editBundle).subscribe();
    return this.newBundle(editBundle);
  }

  deleteBundle(deleteBundle) {
    return this.http.post(
      this.buildUrl(`/api/admin/v1/deleteBundle`),
      deleteBundle
    );
  }

  assignUserGroup(assignGroupUsers) {
    return this.http.post(
      this.buildUrl(`/api/admin/v1/assignGroup`),
      assignGroupUsers
    );
  }

  copyEvents(data) {
    return this.http.post(this.buildUrl("/api/admin/v1/copyEvents"), data);
  }

  scheduleEvent(data) {
    return this.http.post(this.buildUrl("/api/admin/v1/scheduleEvent"), data);
  }

  scheduleCategory(data) {
    return this.http.post(
      this.buildUrl("/api/admin/v1/scheduleCategory"),
      data
    );
  }

  cancelScheduledEvent(id) {
    return this.http.post(this.buildUrl("/api/admin/v1/cancelScheduledEvent"), id);
  }

  cancelScheduledCategory(id) {
    return this.http.post(this.buildUrl("/api/admin/v1/cancelScheduledCategory"), id);
  }

  getLastGame() {
    return this.http.get(this.buildUrl("/api/admin/v1/getLastGame"));
  }

  getFilteredUsers(paramsSent) {
    return this.http.post(
      this.buildUrl(`/api/admin/v1/getFilteredUsers`),
      paramsSent
    );
  }

  getFilteredEvents(paramsSent) {
    return this.http.post(
      this.buildUrl(`/api/admin/v1/getFilteredEvents`),
      paramsSent
    );
  }

  getFilteredGames(paramsSent) {
    return this.http.post(
      this.buildUrl(`/api/admin/v1/getFilteredGames`),
      paramsSent
    );
  }

  createNewGame(newGame) {
    return this.http.post(this.buildUrl(`/api/admin/v1/newGame`), newGame);
  }

  createGameEvent(newEvents) {
    return this.http.post(
      this.buildUrl(`/api/admin/v1/createGameEvent`),
      newEvents
    );
  }

  updateGameEvent(event) {
    return this.http.post(
      this.buildUrl(`/api/admin/v1/updateGameEvent`),
      event
    );
  }

  deleteGameEvent(id) {
    return this.http.post(this.buildUrl(`/api/admin/v1/deleteGameEvent`), id);
  }

  getHashCheckUser(User) {
    return this.http.post(
      this.buildUrl(`/api/admin/v1/getHashCheckUser`),
      User
    );
  }

  getLeaderboard(id) {
    return this.http.get(this.buildUrl(`/api/admin/v1/getLeaderboard/${id}`));
  }

  uploadGameDataFile(FormData) {
    return this.http.post(
      `http://localhost:${this.retraderBackendUrl}/api/admin/legacy/prices/file`,
      FormData
    );
  }

  uploadGameLaunchFile(FormData) {
    return this.http.post(
      `http://localhost:${this.retraderBackendUrl}/api/admin/legacy/price/launchFile`,
      FormData
    );
  }

  uploadGameTimeChange(FormData) {
    return this.http.post(
      this.buildUrl(`/api/admin/v1/uploadGameTimeChange`),
      FormData
    );
  }

  getFilesCategory() {
    return this.http.get(this.buildUrl(`/api/admin/v1/getCategoryFiles`));
  }

  getFilteredLeaderBoard(paramsSent) {
    return this.http.post(
      this.buildUrl(`/api/admin/v1/getFilteredLeaderBoard`),
      paramsSent
    );
  }
  gameAutomatedStrategypnl(gameId) {
    return this.http.get(this.buildUrl(`/api/gameautomatedstrategypnl/${gameId}`));
  }
}
